





























import { Component, Vue } from "vue-property-decorator";
import { REGULAR_HEADER, TableHeader } from "@/models/utils/c-table.interface";
import { Pagination } from "@/models/utils/pagination.interface";
import { CDrawer, DrawerEmployee } from "@/models/utils/c-drawer.interace";
import { regular } from "@/services/api/regular.service";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import TablesPaginationModule from "@/store/modules/tables_pagination.module";
import { calendarJob } from "@/services/api/calendarJob.service";
import { utils } from "@/services/api/utils.service";
import OverlayModule from "@/store/modules/overlay.module";
import NavigationDrawerModule from "@/store/modules/navigationDrawer.module";
import { CalendarJob } from "@/models/entities/calendar-job.interface";
import SearchModule from "@/store/modules/search.module";
const searchModule = getModule(SearchModule, store);
const overlayModule = getModule(OverlayModule, store);
const navigationDrawerModule = getModule(NavigationDrawerModule, store);
const tablesPaginationModule = getModule(TablesPaginationModule, store);

const CDrawerBody = () =>
  import(
    /* webpackChunkName: "CDrawerBody" */ "@/components/CosmopolDrawer/components/CDrawerBody.vue"
  );

@Component({
  components: {
    "c-drawer-body": CDrawerBody,
  },
})
export default class Regular extends Vue {
  private items: CalendarJob[] = [];
  private headers: TableHeader[] = REGULAR_HEADER;
  private isLoading = false;
  private serverItemsLength = 0;
  private source: AbortController = null;
  private timeInterval: number = null;
  private pagination = null as Pagination;
  private drawerItem = {} as CDrawer;
  private showDrawer = false;

  get vuexPagination(): Pagination {
    return tablesPaginationModule.regular;
  }

  private beforeDestroy() {
    clearInterval(this.timeInterval);
  }

  private async created(): Promise<void> {
    this.pagination = this.vuexPagination;
    this.$set(this.pagination, "search", searchModule.search);
    tablesPaginationModule.setRegularPagination(this.pagination);
    await this.loadTableItems();
  }

  private async loadTableItems(): Promise<void> {
    this.isLoading = true;
    this.source && this.source.abort();
    this.source = new AbortController();
    if (this.vuexPagination) this.pagination = this.vuexPagination;
    let r;
    r = await regular.getAllRegular(this.pagination, this.source.signal);
    if (r.message !== "canceled") {
      if (this.checkPagination(r.data.totalRows)) {
        this.items = r.data.items;
        this.serverItemsLength = r.data.totalRows;
        this.isLoading = false;
      } else await this.loadTableItems();
    }
  }

  private checkPagination(totalRows: number): boolean {
    if (totalRows !== 0) {
      const lastPage = Math.ceil(totalRows / this.pagination.rows);
      if (this.pagination.page > lastPage) {
        this.$set(this.pagination, "page", lastPage);
        tablesPaginationModule.setRegularPagination(this.pagination);
        return false;
      }
    }
    return true;
  }

  private mounted(): void {
    try {
      this.timeInterval = setInterval(async () => {
        await this.loadTableItems();
      }, 30000);
    } catch {
      clearInterval(this.timeInterval);
    }
  }

  private async openDrawer(
    item: CalendarJob,
    lastActionId: number
  ): Promise<void> {
    await this.loadDrawerData(item, lastActionId);
    this.showDrawer = true;
  }

  private async loadDrawerData(
    calendarJob: CalendarJob,
    lastActionId: number
  ): Promise<void> {
    await Promise.all([
      this.loadCurrentJob(calendarJob.calendarJobId, lastActionId),
      this.loadJobs(calendarJob.calendarJobId),
      this.loadEmployeesByContractRowId(calendarJob.contractRowId),
      this.loadNotes(calendarJob.calendarJobId),
      this.loadKeepAlives(calendarJob.calendarJobId),
      this.loadStatistics(calendarJob.employeeId, 7),
    ]);
  }

  private async loadCurrentJob(
    calendarJobId: number,
    lastActionId: number
  ): Promise<void> {
    overlayModule.showOverlay();
    let job = await calendarJob.getCalendarJobByCalendarJobId(calendarJobId);
    if (lastActionId) this.$set(job.data, "lastActionId", lastActionId);
    this.$set(this.drawerItem, "currentJob", job.data);
    const employee: DrawerEmployee = {
      employeeFullName: job.data.employeeFullName,
      isSubstitute: job.data.isSubstitute,
      oldEmployee: job.data.oldEmployee,
    };
    navigationDrawerModule.setSelectedEmployee(employee);
  }

  private async loadJobs(calendarJobId: number): Promise<void> {
    overlayModule.showOverlay();
    let jobs = await calendarJob.getAllCalendarJobsByCalendarJobId(
      calendarJobId
    );
    this.$set(this.drawerItem, "jobs", jobs.data);
  }

  private async loadEmployeesByContractRowId(
    contractRowId: number
  ): Promise<void> {
    overlayModule.showOverlay();
    let employees = await calendarJob.getEmployeesByContractRowId(
      contractRowId
    );
    this.$set(this.drawerItem, "employees", employees.data);
  }

  private async loadNotes(calendarJobId: number): Promise<void> {
    overlayModule.showOverlay();
    let notes = await calendarJob.getNotesByCalendarJobId(calendarJobId);
    this.$set(this.drawerItem, "notes", notes.data);
  }

  private async loadKeepAlives(calendarJobId: number): Promise<void> {
    overlayModule.showOverlay();
    let keepAlives = await calendarJob.getKeepAliveByCalendarJobId(
      calendarJobId
    );
    this.$set(this.drawerItem, "keepAlives", keepAlives.data);
  }

  private async loadStatistics(
    employeeId: number,
    days: number
  ): Promise<void> {
    overlayModule.showOverlay();
    this.$set(this.drawerItem, "showChart", false);
    let statistics = await utils.getEmployeeStampsStatistic(employeeId, days);
    this.$set(this.drawerItem, "statistics", statistics.data);
    this.$set(this.drawerItem, "showChart", true);
  }

  private updatePagination(): void {
    tablesPaginationModule.setRegularPagination(this.pagination);
    this.loadTableItems();
  }

  private onJobClick(item: CalendarJob): void {
    this.loadDrawerData(item, undefined);
  }

  private async onSaveNotes(note: string): Promise<void> {
    overlayModule.showOverlay();
    let notes = await calendarJob.saveNote(
      this.drawerItem.currentJob.calendarJobId,
      note
    );
    this.$set(this.drawerItem, "notes", notes.data);
  }
}
