import { CalendarJobById } from "@/models/entities/calendar-job-byId.interface";
import { CalendarJobGeo } from "@/models/entities/Calendar-job-geo.interface";
import { CalendarJob } from "@/models/entities/calendar-job.interface";
import { Employee } from "@/models/entities/employee.interface";
import { KeepAlive } from "@/models/entities/keep-alive.interface";
import { Note } from "@/models/entities/note.interface";
import { ActionRequest } from "@/models/requests/actions-request.interface";
import { OfficeGeoRequest } from "@/models/requests/office-geo-request.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "calendarjob";

class CalendarJobService extends ApiService {
  getCalendarJobByCalendarJobId(calendarJobId: number) {
    return this.getByID<number, CalendarJobById>(CONTROLLER, calendarJobId);
  }
  getAllCalendarJobsByCalendarJobId(
    calendarJobId: number,
    calendarDate?: string
  ) {
    return this.getByID<number, CalendarJob[]>(
      `${CONTROLLER}/all`,
      calendarJobId,
      {
        params: calendarDate
          ? {
              calendarDate: calendarDate,
            }
          : null,
      }
    );
  }
  getEmployeesByContractRowId(contractRowId: number) {
    return this.getAll<Employee[]>(`${CONTROLLER}/${contractRowId}/employees`);
  }
  getNotesByCalendarJobId(calendarJobId: number) {
    return this.getAll<Note[]>(`${CONTROLLER}/${calendarJobId}/notes`);
  }
  saveNote(calendarJobId: number, note: string) {
    return this.post<string, Note[]>(
      `${CONTROLLER}/${calendarJobId}/notes`,
      JSON.stringify(note),
      {
        headers: {
          accept: "text/plain",
          "Content-Type": "application/json",
        },
      }
    );
  }
  getKeepAliveByCalendarJobId(calendarJobId: number) {
    return this.getAll<KeepAlive[]>(
      `${CONTROLLER}/${calendarJobId}/keepalives`
    );
  }
  updateCalendarJobAction(calendarJobId: number, req: ActionRequest) {
    return this.update<ActionRequest, CalendarJob>(
      `${CONTROLLER}/${calendarJobId}/actions`,
      req
    );
  }
  getCoordinatesCalendarJobId(calendarJobId: number) {
    return this.getAll<CalendarJobGeo>(`${CONTROLLER}/${calendarJobId}/geo`);
  }
  saveOfficeCoordinates(
    calendarJobId: number,
    officeId: number,
    request: OfficeGeoRequest
  ) {
    return this.post<OfficeGeoRequest, CalendarJobGeo>(
      `${CONTROLLER}/${calendarJobId}/coordinates/${officeId}`,
      request
    );
  }
}

export const calendarJob = new CalendarJobService();
