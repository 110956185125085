import { StampsStatistic } from "@/models/entities/stamps-statistic.interface";
import { Action } from "@/models/entities/action.interface";
import { Employee } from "@/models/entities/employee.interface";
import { Expertise } from "@/models/entities/expertise.interface";
import { Office } from "@/models/entities/office.interface";
import { Role } from "@/models/entities/role.interface";
import { Service } from "@/models/entities/service.interface";
import { UserType } from "@/models/entities/user-type.interface";
import { ApiService } from "../api.service";
import { Customer, CustomerCombo } from "@/models/entities/customer.interface";
import { Paginated } from "@/models/utils/paginated.interface";

const CONTROLLER = "utils";

class UtilsService extends ApiService {
  getAllRoles() {
    return this.getAll<Role[]>(`${CONTROLLER}/roles`);
  }

  getRolesByUserTypeId(userTypeId: string) {
    return this.getAll<Role[]>(`${CONTROLLER}/roles/usertype/${userTypeId}`);
  }

  getAllUserTypes() {
    return this.getAll<UserType[]>(`${CONTROLLER}/usertypes`);
  }

  getAllAuditors() {
    return this.getAll<string[]>(`${CONTROLLER}/offices/auditors`);
  }

  getActionsByContext(context: string) {
    return this.getByID<string, Action[]>(`${CONTROLLER}/actions`, context);
  }

  getAllExpertise() {
    return this.getAll<Expertise[]>(`${CONTROLLER}/expertise`);
  }

  getAllEmployees() {
    return this.getAll<Omit<Employee, "employeeClassificationName">[]>(
      `${CONTROLLER}/employees`
    );
  }

  getEmployeesByExpertiseId(expertiseId: number) {
    return this.getByID<number, Omit<Employee, "employeeClassificationName">[]>(
      `${CONTROLLER}/employees`,
      expertiseId
    );
  }

  getAllServices() {
    return this.getAll<Service[]>(`${CONTROLLER}/services`);
  }

  getAllOffices() {
    return this.getAll<Office[]>(`${CONTROLLER}/offices`);
  }

  getAllCustomers(page: number, search$: string, signal: AbortSignal = null) {
    const search = search$?.trim()?.length === 0 ? null : search$;
    return this.getAll<Paginated<Customer[]>>(`${CONTROLLER}/customers`, {
      params: { page, search },
      signal,
    });
  }

  getAllCustomersCombo(search: string, signal: AbortSignal = null) {
    return this.getAll<CustomerCombo[]>(`${CONTROLLER}/customers/combo`, {
      params: { search },
      signal,
    });
  }

  getEmployeeStampsStatistic(employeeId: number, days: number) {
    return this.getAll<StampsStatistic[]>(
      `${CONTROLLER}/employee/${employeeId}/stampsstatistic/${days}`
    );
  }
}

export const utils = new UtilsService();
