import { CalendarJob } from "@/models/entities/calendar-job.interface";
import { Paginated } from "@/models/utils/paginated.interface";
import { Pagination } from "@/models/utils/pagination.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "regular";

class RegularService extends ApiService {
  getAllRegular(pagination: Pagination, signal: AbortSignal = null) {
    return this.getAll<Paginated<CalendarJob>>(CONTROLLER, {
      params: pagination,
      signal: signal,
    });
  }
}

export const regular = new RegularService();
